<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="2" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}"></right-tool>
		<div class="content">
			<div class="movies-wrap flex-row" v-if="moviesInfo">
				<div class="img-wrap">
					<div class="label started ft-14" v-if="moviesInfo.startStatus === 'preheating'">预热中</div>
					<div class="label make ft-14" v-if="moviesInfo.startStatus === 'handing'">进行中</div>
					<div class="label make ft-14" v-if="moviesInfo.startStatus === 'released'">已上映</div>
					<!-- <div class="label make ft-14">后期制作</div> -->
					<img :src="moviesInfo.coverUrl" />
					<!-- <div class="status ft-14">{{moviesInfo.startStatus_dictText}}</div> -->
				</div>
				<div class="movies-content flex-col">
					<div class="movies-name">
						<h1 class="ft-28 two-ellipsis">{{moviesInfo.name}}</h1>
					</div>
					<div class="movies-info flex-col ft-12">
						<p v-if="moviesInfo.director">导演：{{moviesInfo.director}}</p>
						<p v-if="moviesInfo.screenwriter">编剧：{{moviesInfo.screenwriter}}</p>
						<p v-if="moviesInfo.toStar">主演： {{moviesInfo.toStar}}</p>
						<p v-if="moviesInfo.type_dictText">类型：{{moviesInfo.type_dictText}}</p>
						<p v-if="moviesInfo.producer">出品方：{{moviesInfo.producer}} </p>
					</div>
					<div class="movies-detail ft-12 three-ellipsis">
						{{moviesInfo.information}}
					</div>
					<div class="applyBtn flex-row ft-14" v-if="moviesInfo.startStatus !== 'released'" @click="applyTap(moviesInfo)">
						<span>申请份额</span>
						<img src="../../assets/images/top_icon.png" />
					</div>
				</div>
			</div>
			<div class="movies-html" v-if="moviesInfo" v-html="moviesInfo.content">
			</div>
		</div>
		<div class="footer flex-row">
			<div class="footer-wrap">
				<div class="top">
					<div class="w80 flex-row">
						<div class="item1 flex-row">
							<div class="title ft-20">联系我们</div>
							<small>Contact Us</small>
						</div>
						<div class="item2 flex-row">
							<p class="ft-14">地址：厦门市集美区杏林湾营运中心11号楼1201</p>
							<p class="ft-14">邮箱：2545817883@qq.com</p>
						</div>
						<div class="item3 flex-row">
							<p class="ft-14">服务时间：周一至周五9:00~20:00 </p>
							<p class="ft-14">邮编：361000</p>
						</div>
						<div class="item4 flex-row">
							<p class="ft-14">联系电话</p>
							<p class="ft-14">15280605328</p>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div class="w80 flex-row">
						<p class="ft-12">浩亿影业 : www.haoyifilmgroup.com 版权所有 | <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备18025946号-1</a></p>
					</div>
				</div>
			</div>
		</div>
		<div class="back-wrap flex-row" @click="backTap">
			<img src="../../assets/images/back_icon.png" >
		</div>
	</div>
</template>

<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import { moviesDetail,shareApply } from '@/api/api.js'
	// import $ from 'jquery'
	export default{
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				minBar: false,
				showMenu: false,
				scrollTop: 0,
				btnFlag: false,
				moviesInfo: null
			}
		},
		created() {
			if(this.$route.query.id) {
				this.getDetail(this.$route.query.id)
			}
		},
		mounted() {
			document.body.style.cssText = "height: 100%;"
			window.addEventListener('scroll',this.scrollToTop,true)
		},
		destroyed() {
			window.removeEventListener('scroll',this.scrollToTop)
		},
		methods: {
			backTap() {
				this.$router.back()
			},
			changeMenu(bool) {
				this.showMenu = !bool
			},
			applyTap(item) {
				if (this.$store.state.user.isLogin) {
					shareApply({moviesId: item.id}).then(res =>{
						if (res.success) {
							this.$refs.header.regSuccess({title: '份额申请成功！请等待工作人员与您联系！'})
						} else {
							this.$refs.header.showError({title: res.message})
						}
					})
				} else {
					this.$refs.header.login()
				}
			},
			scrollToTop() {
				const that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				that.scrollTop = scrollTop
				if (this.scrollTop > 600) {
					this.btnFlag = true
				} else {
					this.btnFlag = false
				}
			},
			getDetail(id) {
				moviesDetail({id: id}).then(res =>{
					if (res.success) {
						this.moviesInfo = res.result
					}
				})
			}
		}
	}
</script>
<style scoped>
	.movies-html /deep/ img{
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}
</style>
<style lang="scss" scoped>
	.container{
		padding-top: 16vh;
		nav{
			background-color: #111111;
		}
		.content{
			width: 72%;
			margin: 0 auto;
			min-height: 52vh;
			.movies-wrap{
				width: 100%;
				margin: 65px 0;
				justify-content: space-between;
				align-items: flex-start;
				color: #FFF;
				.img-wrap{
					position: relative;
					width: 60vw;
					.label{
						position: absolute;
						top: 14px;
						right: 0;
						height: 26px;
						line-height: 26px;
						padding: 0 10px;
						color: #FFF;
						border-radius: 4px 0 0 4px;
						&.started{
							background: linear-gradient(180deg, #2595CC 0%, #1679DC 100%);
						}
						&.make{
							background-color: #CE9236;
						}
					}
					.status{
						width: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						height: 74px;
						text-align: right;
						font-weight: bold;
						color: #FF6C00;
						padding: 20px 14px;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
					}
					img{
						display: block;
						width: 100%;
						object-fit: cover;
					}
				}
				.movies-content{
					width: 40vw;
					min-height: 25em;
					justify-content: flex-start;
					align-items: flex-start;
					padding-left: 5vw;
					div{
						width: 100%;
						&.movies-name{
							h1{
								line-height: 1.5em;
								max-height: 1.5em;
								overflow: hidden;
							}
						}
						&.movies-info{
							line-height: 1.8em;
							justify-content: flex-start;
							align-items: flex-start;
							margin: 16px 0;
						}
						&.movies-detail{
							line-height: 1.8em;
							max-height: 5.4em;
							height: 5.4em;
							overflow: hidden;
						}
						&.applyBtn{
							width: 104px;
							height: 38px;
							background: #111111;
							border-radius: 3px;
							border: 1px solid #999999;
							font-weight: bold;
							margin-top: 3vh;
							cursor: pointer;
							img{
								width: 14px;
								height: 9px;
								margin-left: 4px;
								transform: rotate(90deg);
							}
						}
					}
				}
			}
			/deep/ .movies-html{
				padding: 88px 100px;
				background-color: #1A1A1A;
				color: #FFF;
				font-size: 16px;
				h1, h2, h3, h4, h5, h6{
					font-weight: bold;
				}
				p{
					font-size: 16px;
					color: #FFF;
				}
				img{
					display: block;
					max-width: 100%;
				}
			}
		}
		.footer {
			width: 100%;
			height: 22vh;
			margin-top: 10vh;
			background: #1D1D1D;
		
			.footer-wrap {
				width: 100%;
				height: 100%;
				margin: 0 auto;
		
				.top {
					width: 100%;
					height: calc(100% - 8vh);
					padding: 3vh 0;
		
					.w80 {
						width: 72%;
						margin: 0 auto;
						height: 100%;
		
						.flex-row {
							align-items: flex-start;
							flex-grow: 1;
							height: 100%;
							flex-direction: column;
							color: #7A7A7A;
							animation-delay: .25s;
							p {
								margin-top: 1vh;
							}
		
							&.item2,
							&.item3 {
								flex-grow: 2;
							}
							&.item2{
								animation-delay: .5s;
							}
							&.item3{
								animation-delay: .75s;
							}
							&.item4{
								animation-delay: 1s;
							}
							.title {
								color: #FFF;
							}
						}
					}
				}
		
				.bottom {
					position: relative;
					width: 100%;
					height: 8vh;
		
					&::before {
						position: absolute;
						left: 0;
						top: 0;
						content: '';
						width: 100%;
						height: 1px;
						background-color: #3F4D6C;
						opacity: 0.2477;
					}
		
					.w80 {
						width: 72%;
						margin: 0 auto;
						height: 100%;
						justify-content: flex-start;
		
						p {
							color: #6B6B6B;
							animation-delay: .8s;
							a{
								color: #6B6B6B;
							}
						}
					}
				}
			}
		}
		.back-wrap{
			position: fixed;
			right: 8%;
			bottom: 20%;
			width: 3.5vw;
			height: 3.5vw;
			background-color: #2E2E2E;
			border-radius: 50%;
			img{
				width: 20px;
				height: 17px;
			}
		}
	}
</style>
